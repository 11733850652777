import React, { useState, useEffect } from "react";
import Rotas from "./components/routes/Rotas";
import * as apiCarros from "./api/apiCarros";
import * as apiMotas from "./api/apiMotas";
import * as apiEmpresa from "./api/apiEmpresa";
import * as apiJson from "./api/apiJson";
import useScriptWidgetsWhats from "./components/hooks/useScriptWidgetsWhats";
import useGaTracker from "./components/hooks/useGaTracker";
import useStateWithLocalStorage from "./components/hooks/useStateWithLocalStorage ";
import useMediaQuery from "./components/hooks/useMediaQuery ";
import Siteholder from "./components/tools/placeholder/Siteholder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import ReactSEOMetaTags from "react-seo-meta-tags";
import { lightTheme, darkTheme } from "./config/theme";
import GlobalTheme from "./config/global";
import CookieConsentir from "./components/tools/cookies/CookieConsentir";
import Snow from "./components/tools/snowFall/Snow";

const global = require("./config/config");
export default function App() {
  const [infoJson, setInfoJson] = useState([]);
  const [viatura, setViatura] = useState([]);
  const [ficha, setFicha] = useState(false);
  const [metaTags, setMetaTags] = useState([]);
  const [allLista, setAllLista] = useState([]);
  const [allListaCarros, setAllListaCarros] = useState([]);
  const [allListaComercias, setAllListaComercias] = useState([]);
  const [allListaClassicos, setAllListaClassicos] = useState([]);
  const [allListaMotas, setAllListaMotas] = useState([]);
  const [allinfo, setAllinfo] = useState([]);
  const [infoPrincipal, setInfoPrincipal] = useState([]);
  const [codPontoVenda, setTemPontoVenda] = useState(0);
  const [selectedCarsComparar, setSelectedCarsComparar] =
    useStateWithLocalStorage("myValueInLocalStorage");
  const [showModalComparison, setShowModalComparison] = useState(false);
  const [textoModal, setTextoModal] = useState(false);
  const [allinfoText, setAllinfoText] = useState([]);

  useEffect(() => {
    const getJson = async () => {
      const info = await apiJson.infoJson();
      setInfoJson(info);
    };

    getJson();
  }, []);
  useEffect(() => {
    const getAllinfoText = async () => {
      const info = await apiEmpresa.getInfoTextos(infoJson);
      setAllinfoText(info);
    };
    Object.keys(infoJson).length !== 0 && getAllinfoText();
  }, [infoJson]);

  useScriptWidgetsWhats(infoJson);
  useGaTracker(infoJson);

  useEffect(() => {
    const getViaturas = async () => {
      const viaturas = await apiCarros.getListaViaturas(infoJson);
      let motas = [];

      if (infoJson.Stand.Easymanager) {
        motas = [];
      } else {
        motas = await apiMotas.getListaMotas(infoJson);
      }

      if (infoJson.Layout.TudoJunto && motas.length !== 0) {
        const vJuntas = viaturas
          .concat(motas)
          .sort(
            (a, b) => new Date(b.UltimaAlteracao) - new Date(a.UltimaAlteracao)
          );
        setAllLista(vJuntas);
      } else {
        setAllLista(viaturas);
      }

      setAllListaMotas(motas);
      const Carros = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 && global.carrosNormais.includes(carro.Tipo.Id)
        );
      });

      setAllListaCarros(Carros);
      const Comercias = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 &&
          global.carrosComerciais.includes(carro.Tipo.Id)
        );
      });

      setAllListaComercias(Comercias);
      const Classicos = viaturas.filter((carro) => {
        return carro.Estado.Id === 41 && carro.Vendido === false;
      });
      setAllListaClassicos(Classicos);
    };
    if (Object.keys(infoJson).length !== 0) {
      getViaturas();
    }
  }, [infoJson]);

  useEffect(() => {
    const getEmpresas = async () => {
      const info = await apiEmpresa.getInfoEmpresa(infoJson);
      let infoStand;
      codPontoVenda === 0
        ? (infoStand = info)
        : (infoStand = info.filter((empresa) => {
            return empresa.CodPontoVenda === codPontoVenda;
          }));
      setAllinfo(infoStand);

      const infoPrincipal = info.filter((empresa) => {
        return empresa.Principal === true;
      });

      setInfoPrincipal(infoPrincipal);
    };

    Object.keys(infoJson).length !== 0 && getEmpresas();
  }, [codPontoVenda, infoJson]);

  const isDesktop = useMediaQuery("(min-width: 992px)");

  const addToCompare = (item) => {
    let numLimit;

    !isDesktop ? (numLimit = 2) : (numLimit = 3);

    if (
      selectedCarsComparar.length >= 1 &&
      item.Viatura !== selectedCarsComparar[0].Viatura
    ) {
      setShowModalComparison(true);
      setTextoModal(
        " O viatura que tentaste adicionar não é compatível com os restantes viaturas no comparador."
      );
      return;
    }

    if (selectedCarsComparar.length >= numLimit) {
      setShowModalComparison(true);
      setTextoModal(
        " O limite de viaturas no comparador foi atingido. Por favor, remove uma viatura para poderes adicionar mais."
      );
    } else {
      setSelectedCarsComparar((selectedCarsComparar) => [
        ...selectedCarsComparar,
        item,
      ]);
    }
  };

  const removeFromCompare = (item) => {
    const filteredItems = selectedCarsComparar.filter(
      (product) => product.CodViatura !== item.CodViatura
    );
    setSelectedCarsComparar((selectedCarsComparar) => filteredItems);
  };
  const removeFromCompareAll = () => {
    setSelectedCarsComparar([]);
  };

  useEffect(() => {
    selectedCarsComparar.length > 0 &&
      (document.body.style.overflow = "visible");
  }, [selectedCarsComparar]);

  return (
    <>
      {Object.keys(infoJson).length !== 0 && (
        <>
          <ThemeProvider
            theme={infoJson.Layout.ThemeDark ? darkTheme : lightTheme}
          >
            <GlobalTheme infoJson={infoJson} />
            <HelmetProvider>
              <ReactSEOMetaTags
                render={(el) => (
                  <Helmet>
                    {/* Chrome, Firefox OS and Opera  */}
                    <meta
                      name="theme-color"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {/* Windows Phone  */}
                    <meta
                      name="msapplication-navbutton-color"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {/* iOS Safari  */}
                    <meta
                      name="apple-mobile-web-app-status-bar-style"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {infoJson.Stand.Lead2team.existe && (
                      <script type="text/javascript">
                        {` 
              (function (w,d,s,o,f,js,fjs,mpd) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
              mpd = d.createElement('div');
              mpd.id = 'widget_'+o; d.body.appendChild(mpd); js = d.createElement(s), fjs = d.getElementById('widget_'+o); js.id = o; js.src = f; 
              js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'lead2team', 'https://online.lead2team.com/widget/widget.js'));
              lead2team('init', { apiKey: '${infoJson.Stand.Lead2team.apiKey}' });
              lead2team('lead2team', { groups: '', locations: ''}); 
            `}
                      </script>
                    )}

                    {el}
                  </Helmet>
                )}
                website={metaTags.fullWebsiteData}
                organization={metaTags.organization}
                facebook={metaTags.facebookData}
                twitter={metaTags.twitterData}
                blogPost={metaTags.blogPostData}
              />
            </HelmetProvider>

            {infoJson.Stand.Snowfall.existe && <Snow infoJson={infoJson} />}

            {allLista.length === 0 ? (
              <>
                <Siteholder infoJson={infoJson} />
              </>
            ) : (
              <>
                <Rotas
                  allLista={allLista}
                  allListaCarros={allListaCarros}
                  allListaComercias={allListaComercias}
                  allListaClassicos={allListaClassicos}
                  allListaMotas={allListaMotas}
                  allinfo={infoPrincipal}
                  pontosVenda={allinfo}
                  setTemPontoVenda={setTemPontoVenda}
                  setMetaTags={setMetaTags}
                  viatura={viatura}
                  setViatura={setViatura}
                  ficha={ficha}
                  setFicha={setFicha}
                  infoJson={infoJson}
                  selectedItems={selectedCarsComparar}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                  allinfoText={allinfoText}
                  infoPrincipal={infoPrincipal}
                  selectedCarsComparar={selectedCarsComparar}
                  removeFromCompareAll={removeFromCompareAll}
                  setTextoModal={setTextoModal}
                  textoModal={textoModal}
                  setShowModalComparison={setShowModalComparison}
                  showModalComparison={showModalComparison}
                />
              </>
            )}
            <CookieConsentir />
          </ThemeProvider>
        </>
      )}
    </>
  );
}
