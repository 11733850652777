import React, { lazy, Suspense } from "react";
import Marcas from "./marcas/Marcas";
import Vendidos from "./vendidos/Vendidos";

const AlbumHome = lazy(() => import("./albumHome/AlbumHome"));
const Destaques = lazy(() => import("./destaques/Destaques"));
const EmpresaHome = lazy(() => import("./empresa/EmpresaHome"));
const IntervaloMensalidade = lazy(() =>
  import("./intervaloMensalidade/IntervaloMensalidade")
);
const IntervaloPreco = lazy(() => import("./intervaloPreco/IntervaloPreco"));
const Listagem = lazy(() => import("./listagem/Listagem"));
const NoticiasHome = lazy(() => import("./noticiasHome/NoticiasHome"));
const Servicos = lazy(() => import("./servicos/Servicos"));
const TestemunhosHome = lazy(() => import("./testemunhosHome/TestemunhosHome"));
const Tipos = lazy(() => import("./tipos/Tipos"));
const Recentes = lazy(() => import("./utimasEntradas/Recentes"));

export default function SectionList({
  infoJson,
  allLista,
  filteredCarros,
  allListaCarros,
  setFilteredCarros,
  selectedItems,
  addToCompare,
  removeFromCompare,
  allinfo,
  allinfoText,
  pontosVenda,
}) {
  const renderSwitch = (param) => {
    switch (param) {
      case "Destaques":
        return (
          <>
            {infoJson.Layout.Destaques.existe && (
              <>
                <Destaques
                  allLista={allLista}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                  allinfo={allinfo}
                  allinfoText={allinfoText}
                />
              </>
            )}
          </>
        );
      case "UltimasEntradas":
        return (
          <>
            {infoJson.Layout.UltimasEntradas.existe && (
              <>
                {allLista.length !== 0 && (
                  <Recentes
                    allLista={allLista}
                    infoJson={infoJson}
                    allinfoText={allinfoText}
                  />
                )}
              </>
            )}
          </>
        );
      case "Vendidos":
        return (
          <>
            {infoJson.Layout.Vendidos.existe && (
              <>
                {allLista.length !== 0 && (
                  <Vendidos allLista={allLista} infoJson={infoJson} />
                )}
              </>
            )}
          </>
        );
      case "Marcas":
        return (
          <>
            {infoJson.Layout.Marcas.existe && (
              <>
                {allLista.length !== 0 && (
                  <Marcas allLista={allLista} infoJson={infoJson} />
                )}
              </>
            )}
          </>
        );
      case "Servicos":
        return (
          <>
            {infoJson.Layout.Servicos.existe && (
              <>
                <Servicos infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      case "Tipos":
        return (
          <>
            {infoJson.Layout.Tipos.existe && (
              <>
                {allLista.length !== 0 && (
                  <Tipos
                    allLista={allLista}
                    infoJson={infoJson}
                    allinfoText={allinfoText}
                  />
                )}
              </>
            )}
          </>
        );
      case "intervaloPreco":
        return (
          <>
            {infoJson.Layout.intervaloPreco.existe && (
              <>
                <IntervaloPreco infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      case "Listagem":
        return (
          <>
            {infoJson.Layout.Carros.existe && (
              <>
                <Listagem
                  infoJson={infoJson}
                  filteredCarros={filteredCarros}
                  allListaCarros={allListaCarros}
                  allLista={allLista}
                  setFilteredCarros={setFilteredCarros}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                  allinfo={allinfo}
                  allinfoText={allinfoText}
                  pontosVenda={pontosVenda}
                />
              </>
            )}
          </>
        );
      case "AlbumClientes":
        return (
          <>
            {infoJson.Layout.AlbumClientes.existe && (
              <>
                <AlbumHome infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      case "Noticias":
        return (
          <>
            {infoJson.Layout.Noticias.existe && (
              <>
                <NoticiasHome infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      case "Testemunhos":
        return (
          <>
            {infoJson.Layout.Testemunhos.existe && (
              <>
                <TestemunhosHome
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </>
            )}
          </>
        );
      case "intervaloMensalidades":
        return (
          <>
            {infoJson.Layout.intervaloMensalidades.existe && (
              <>
                <IntervaloMensalidade
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </>
            )}
          </>
        );
      case "QuemSomos":
        return (
          <>
            {infoJson.Layout.QuemSomos.existe && (
              <>
                <EmpresaHome infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Suspense fallback={<></>}>
        {infoJson.Home.sort((a, b) => a.ordenador - b.ordenador).map((item) => {
          return (
            <React.Fragment key={item.id}>
              {renderSwitch(item.nome)}
            </React.Fragment>
          );
        })}
      </Suspense>
    </>
  );
}
