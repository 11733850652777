import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import css from "../header.module.css";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Listagem } from "../../../../assets/icons/menu/listagem.svg";
import { ReactComponent as Serv } from "../../../../assets/icons/menu/serv.svg";
import { ReactComponent as Pesq } from "../../../../assets/icons/menu/pesq.svg";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/contact.svg";

import { ReactComponent as SvgOficina } from "../../../../assets/icons/servicos/svg_oficina.svg";
import { ReactComponent as SvgFinanciamento } from "../../../../assets/icons/servicos/svg_financiamento.svg";
import { ReactComponent as SvgLavagens } from "../../../../assets/icons/servicos/svg_lavagens.svg";
import { ReactComponent as SvgSeguros } from "../../../../assets/icons/servicos/svg_seguros.svg";
import { ReactComponent as SvgTestemunhos } from "../../../../assets/icons/servicos/svg_testemunhos.svg";
import { ReactComponent as SvgGaleria } from "../../../../assets/icons/servicos/svg_galeria.svg";
import { ReactComponent as SvgAlbumClientes } from "../../../../assets/icons/servicos/svg_album_clientes.svg";
import { ReactComponent as SvgNoticias } from "../../../../assets/icons/servicos/svg_play.svg";
import { ReactComponent as SvgCompramos } from "../../../../assets/icons/servicos/svg_compramos.svg";
import { ReactComponent as SvgConsultadoria } from "../../../../assets/icons/servicos/svg_consultadoria.svg";
import { ReactComponent as SvgAluguer } from "../../../../assets/icons/servicos/svg_aluguer.svg";
import { ReactComponent as SvgGarantia } from "../../../../assets/icons/servicos/svg_garantia.svg";
import { ReactComponent as Comercial } from "../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../assets/icons/tipo/class.svg";

import CustumNavDropdown from "./CustumNavDropdown";
import CostumNavLink from "./CostumNavLink";
import TradutorHome from "./TradutorHome";
import MobileDetect from "mobile-detect";

export default function NavBarCollapse({
  infoJson,
  allListaCarros,
  allListaComercias,
  allListaClassicos,
  allListaMotas,
  allinfoText,
}) {
  const location = useLocation();
  let userAgent;
  let deviceType;

  userAgent = navigator.userAgent;
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = "tablet";
  } else if (md.mobile()) {
    deviceType = "mobile";
  } else {
    deviceType = "desktop";
  }

  const [AboutDropdownV, setAboutDropdownV] = useState(false);
  const [AboutDropdownS, setAboutDropdownS] = useState(false);

  const [activeDropS, setactiveDropS] = useState(false);
  const [activeDropV, setactiveDropV] = useState(false);

  useEffect(() => {
    if (location.state && location.state.noative) {
      setactiveDropS(false);
      setactiveDropV(false);
    }
  }, [location]);

  return (
    <>
      <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
        <Nav className="mr-auto">
          {infoJson.Layout.MenuJunto ? (
            <Nav.Link
              className={css.padding}
              as={Link}
              to={"/viaturas/usadas/"}
            >
              <div className={`${css.boxMenu} boxMenu`}>
                <Listagem className=" p-0 mb-2 " height={`27px`} />
                Viaturas
              </div>
              <span></span>
            </Nav.Link>
          ) : (
            <NavDropdown
              title={
                <>
                  <div
                    className={`${css.boxMenu} ${
                      activeDropV ? ` ${css.active} active` : ""
                    }  boxMenu`}
                  >
                    <Listagem className=" p-0 mb-2 " height={`27px`} />
                    Viaturas
                  </div>
                  <span></span>
                </>
              }
              className={css.padding}
              id="basic-nav-dropdown1"
              show={AboutDropdownV}
              onClick={() => {
                deviceType !== "desktop" && setAboutDropdownV(!AboutDropdownV);
              }}
              onMouseEnter={() =>
                deviceType === "desktop" && setAboutDropdownV(true)
              }
              onMouseLeave={() =>
                deviceType === "desktop" && setAboutDropdownV(false)
              }
              renderMenuOnMount={true}
            >
              {allListaCarros.length > 0 && (
                <CustumNavDropdown
                  setactive={setactiveDropV}
                  noactive={setactiveDropS}
                  to={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "automoveis/#listagem_viaturas"
                        : "/viaturas/automoveis"
                      : "/#listagem_viaturas"
                  }
                >
                  <Berlina
                    style={{
                      width: "45px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                  Automóveis
                </CustumNavDropdown>
              )}

              {allListaComercias.length > 0 && (
                <CustumNavDropdown
                  setactive={setactiveDropV}
                  noactive={setactiveDropS}
                  to={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "comerciais/#listagem_viaturas"
                        : "/viaturas/comerciais"
                      : "/carros/comerciais/"
                  }
                >
                  <Comercial
                    style={{
                      width: "45px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                  Comerciais
                </CustumNavDropdown>
              )}

              {allListaClassicos.length > 0 && (
                <CustumNavDropdown
                  setactive={setactiveDropV}
                  noactive={setactiveDropS}
                  to={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "classicos/#listagem_viaturas"
                        : "/viaturas/classicos"
                      : "/carros/classicos/"
                  }
                >
                  <Classico
                    style={{
                      width: "45px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                  Clássicos
                </CustumNavDropdown>
              )}

              {allListaMotas.length > 0 && (
                <CustumNavDropdown
                  setactive={setactiveDropV}
                  noactive={setactiveDropS}
                  to={
                    infoJson.Layout.TudoJunto
                      ? infoJson.Layout.Carros.existe
                        ? "motas/#listagem_viaturas"
                        : "/viaturas/motas"
                      : "/motas/usadas/"
                  }
                >
                  <Motos
                    style={{
                      width: "45px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                  Motos
                </CustumNavDropdown>
              )}
              {/* {allListaCarros.length && (
                  <CustumNavDropdown
                    setactive={setactiveDropV}
                    noactive={setactiveDropS}
                    to={
                      infoJson.Layout.TudoJunto
                        ? infoJson.Layout.Carros.existe
                          ? "barcos/#listagem_viaturas"
                          : "/viaturas/barcos"
                        : "/barcos/usados/"
                    }
                  >
                    Barcos
                  </CustumNavDropdown>
                )} */}
            </NavDropdown>
          )}

          <Nav.Link
            className={css.padding}
            as={Link}
            to={`${infoJson.Layout.Carros.existe ? "/" : "/viaturas/usadas/"}`}
            state={{ pesquisaAvancada: true }}
          >
            <div className={`${css.boxMenu} boxMenu`}>
              <Pesq className=" p-0 mb-2 " height={`27px`} />
              Pesquisar
            </div>
            <span></span>
          </Nav.Link>
          <NavDropdown
            title={
              <>
                <div
                  className={`${css.boxMenu} ${
                    activeDropS ? ` ${css.active} active` : ""
                  }  boxMenu`}
                >
                  <Serv className=" p-0 mb-2 " height={`27px`} />
                  Serviços
                </div>
                <span></span>
              </>
            }
            id="basic-nav-dropdown2"
            className={css.padding}
            show={AboutDropdownS}
            onClick={() => {
              deviceType !== "desktop" && setAboutDropdownS(!AboutDropdownS);
            }}
            onMouseEnter={() =>
              deviceType === "desktop" && setAboutDropdownS(true)
            }
            onMouseLeave={() =>
              deviceType === "desktop" && setAboutDropdownS(false)
            }
            renderMenuOnMount={true}
          >
            {infoJson.Oficina.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Oficina && allinfoText.Oficina.Url !== ""
                    ? allinfoText.Oficina.Url
                    : infoJson.Oficina.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgOficina
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Oficina && allinfoText.Oficina.Titulo !== ""
                  ? allinfoText.Oficina.Titulo
                  : infoJson.Oficina.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Financiamento.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Financiamento &&
                  allinfoText.Financiamento.Url !== ""
                    ? allinfoText.Financiamento.Url
                    : infoJson.Financiamento.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgFinanciamento
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Financiamento &&
                allinfoText.Financiamento.Titulo !== ""
                  ? allinfoText.Financiamento.Titulo
                  : infoJson.Financiamento.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Lavagens.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Lavagens && allinfoText.Lavagens.Url !== ""
                    ? allinfoText.Lavagens.Url
                    : infoJson.Lavagens.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgLavagens
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Lavagens && allinfoText.Lavagens.Titulo !== ""
                  ? allinfoText.Lavagens.Titulo
                  : infoJson.Lavagens.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Seguros.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Seguros && allinfoText.Seguros.Url !== ""
                    ? allinfoText.Seguros.Url
                    : infoJson.Seguros.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgSeguros
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Seguros && allinfoText.Seguros.Titulo !== ""
                  ? allinfoText.Seguros.Titulo
                  : infoJson.Seguros.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Testemunhos.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                    ? allinfoText.Testemunhos.Url
                    : infoJson.Testemunhos.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgTestemunhos
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Testemunhos &&
                allinfoText.Testemunhos.Titulo !== ""
                  ? allinfoText.Testemunhos.Titulo
                  : infoJson.Testemunhos.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Galeria.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Galeria && allinfoText.Galeria.Url !== ""
                    ? allinfoText.Galeria.Url
                    : infoJson.Galeria.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgGaleria
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Galeria && allinfoText.Galeria.Titulo !== ""
                  ? allinfoText.Galeria.Titulo
                  : infoJson.Galeria.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.AlbumClientes.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.AlbumClientes &&
                  allinfoText.AlbumClientes.Url !== ""
                    ? allinfoText.AlbumClientes.Url
                    : infoJson.AlbumClientes.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgAlbumClientes
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.AlbumClientes &&
                allinfoText.AlbumClientes.Titulo !== ""
                  ? allinfoText.AlbumClientes.Titulo
                  : infoJson.AlbumClientes.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Noticias.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                    ? allinfoText.Noticias.Url
                    : infoJson.Noticias.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgNoticias
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Noticias && allinfoText.Noticias.Titulo !== ""
                  ? allinfoText.Noticias.Titulo
                  : infoJson.Noticias.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Compramos.existe && (
              <CustumNavDropdown
                to={
                  infoJson.Compramos.easybuy.existe
                    ? infoJson.Compramos.easybuy.url
                    : allinfoText.Compramos && allinfoText.Compramos.Url !== ""
                    ? allinfoText.Compramos.Url
                    : infoJson.Compramos.url
                }
                target={infoJson.Compramos.easybuy.existe ? "_blank" : "_self"}
                rel="noopener noreferrer"
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgCompramos
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Compramos && allinfoText.Compramos.Titulo !== ""
                  ? allinfoText.Compramos.Titulo
                  : infoJson.Compramos.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Consultadoria.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Consultadoria &&
                  allinfoText.Consultadoria.Url !== ""
                    ? allinfoText.Consultadoria.Url
                    : infoJson.Consultadoria.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgConsultadoria
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Consultadoria &&
                allinfoText.Consultadoria.Titulo !== ""
                  ? allinfoText.Consultadoria.Titulo
                  : infoJson.Consultadoria.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Aluguer.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Aluguer && allinfoText.Aluguer.Url !== ""
                    ? allinfoText.Aluguer.Url
                    : infoJson.Aluguer.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgAluguer
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Aluguer && allinfoText.Aluguer.Titulo !== ""
                  ? allinfoText.Aluguer.Titulo
                  : infoJson.Aluguer.titulo}
              </CustumNavDropdown>
            )}
            {infoJson.Garantia.existe && (
              <CustumNavDropdown
                to={
                  allinfoText.Garantia && allinfoText.Garantia.Url !== ""
                    ? allinfoText.Garantia.Url
                    : infoJson.Garantia.url
                }
                setactive={setactiveDropS}
                noactive={setactiveDropV}
              >
                <SvgGarantia
                  style={{
                    width: "auto",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                {allinfoText.Garantia && allinfoText.Garantia.Titulo !== ""
                  ? allinfoText.Garantia.Titulo
                  : infoJson.Garantia.titulo}
              </CustumNavDropdown>
            )}
          </NavDropdown>

          {infoJson.Contactos.existe && (
            <CostumNavLink
              className={css.padding}
              as={Link}
              to={
                allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                  ? allinfoText.Contactos.Url
                  : infoJson.Contactos.url
              }
              onClick={() => {
                setactiveDropS(false);
                setactiveDropV(false);
              }}
            >
              <Contact className=" p-0 mb-2 " height={`27px`} />
              {infoJson.Contactos.titulo}
            </CostumNavLink>
          )}
          {infoJson.Layout.Tradutor && <TradutorHome />}
        </Nav>
      </Navbar.Collapse>
    </>
  );
}
